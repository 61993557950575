import { Box, TextField, Typography } from '@mui/material';
import { Dispatch, FC, SetStateAction } from 'react';
import ReusableButton from '../buttons/ReusableButton';
import BaseModal from './BaseModal';
import { RequestAction } from '../../routes/Admin/UserRequest';

interface ModalProps {
  open: boolean;
  onCloseSingleDeny: () => void;
  onCloseSingleApprove: () => void;
  onCloseMultipleDeny: () => void;
  onCloseMultipleApprove: () => void;
  setStatusReason: Dispatch<SetStateAction<string>>;
  setIsStatusEmpty: Dispatch<SetStateAction<boolean>>;
  isStatusEmpty: boolean;
  isRequestPartOfGroup: boolean;
  onReturnBack: () => void;
  loading: boolean;
  confirmationAction: RequestAction | null;
  statusReason: string;
}

// deny modal reduced in size since base modal component was created
const ConfirmationModalComponent: FC<ModalProps> = ({
  open,
  onCloseSingleDeny,
  onCloseMultipleDeny,
  onCloseSingleApprove,
  onCloseMultipleApprove,
  setStatusReason,
  isRequestPartOfGroup,
  isStatusEmpty,
  setIsStatusEmpty,
  onReturnBack,
  loading,
  confirmationAction,
  statusReason,
}) => {
  const content = (
    <>
      <Typography sx={{ textAlign: 'left', fontSize: '1.15rem', paddingTop: '0.7rem' }}>
        Please provide a reason for{' '}
        {confirmationAction === RequestAction.Approved ? 'approving' : 'denying'} this request.
      </Typography>
      <TextField
        sx={{ width: '16rem' }}
        onChange={(e) => {
          setStatusReason(e.target.value);
          setIsStatusEmpty(!e.target.value.trim());
        }}
      />
      {isStatusEmpty && confirmationAction === RequestAction.Denied && (
        <Typography sx={{ fontSize: '0.7rem', color: 'red', paddingRight: 2.5 }}>
          Must enter a reason for denying this request.
        </Typography>
      )}
    </>
  );

  const getAction = () => {
    if (!isRequestPartOfGroup) {
      if (confirmationAction === RequestAction.Approved) {
        onCloseSingleApprove();
      } else if (confirmationAction === RequestAction.Denied) {
        if (statusReason.length > 0) {
          onCloseSingleDeny();
        } else {
          setIsStatusEmpty(true);
        }
      } else {
        null;
      }
    } else {
      if (confirmationAction === RequestAction.Approved) {
        onCloseMultipleApprove();
      } else if (confirmationAction === RequestAction.Denied) {
        onCloseMultipleDeny();
      } else {
        null;
      }
    }
  };

  const actions = (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
        <ReusableButton
          disabled={loading}
          aria-label='Confirm'
          onClick={() => {
            getAction();
            setStatusReason('');
          }}
          style={{ marginLeft: 0 }}
        >
          Confirm
        </ReusableButton>
        <ReusableButton aria-label='Cancel' onClick={onReturnBack}>
          Cancel
        </ReusableButton>
      </Box>
    </>
  );

  return <BaseModal open={open} content={content} actions={actions} />;
};

export default ConfirmationModalComponent;
