import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from '@mui/material';
import ReusableButton from '../buttons/ReusableButton';
import styled, { useTheme } from 'styled-components';
import formatDate from '../../utils/FormatDate';
import { useLocation, useNavigate } from 'react-router-dom';
import { capitalizeRequestType } from '../../utils/capitalizeRequestType';

interface TableData {
  status: string;
  start: string;
  hours: number;
  id: number;
  eventType: { code: string };
}

interface TabTableProps {
  data: TableData[];
  type: string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0.5),
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(0.1),
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0.75),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(1),
  },
  fontSize: '1rem',
}));

const StyledTableCellLeftAlign = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0.75),
  textAlign: 'left',
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(0.1),
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0.75),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(1),
  },
  fontSize: '1rem',
}));

const TabTable: React.FC<TabTableProps> = ({ data = [], type }) => {
  const location = useLocation();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('430'));
  const navigate = useNavigate();
  return (
    <Box sx={{ display: 'flex', margin: '0 auto', paddingTop: '1rem', width: '100%' }}>
      <TableContainer sx={{ overflow: 'auto' }}>
        <Table stickyHeader>
          <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
            <TableRow>
              <StyledTableCell
                sx={{ fontWeight: 'bold', minWidth: '6rem', backgroundColor: 'white' }}
              >
                Status
              </StyledTableCell>
              <StyledTableCell
                sx={{ fontWeight: 'bold', minWidth: '7rem', backgroundColor: 'white' }}
              >
                Date
              </StyledTableCell>
              <StyledTableCell
                sx={{ fontWeight: 'bold', minWidth: '4rem', backgroundColor: 'white' }}
              >
                Hours
              </StyledTableCell>
              {!sm && (
                <StyledTableCell
                  sx={{ fontWeight: 'bold', minWidth: '4rem', backgroundColor: 'white' }}
                >
                  Action
                </StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.some((row) => type === row.eventType.code) ? (
              data.map((row, index) =>
                type === row.eventType.code ? (
                  <TableRow
                    key={`${row.start}-${index}`}
                    onClick={() =>
                      sm &&
                      navigate(
                        location.pathname === '/event-history' && row.status === 'Pending'
                          ? `/requests/edit/${row.id}`
                          : `/requests/view/${row.id}`
                      )
                    }
                  >
                    <StyledTableCellLeftAlign>{row.status}</StyledTableCellLeftAlign>
                    <StyledTableCellLeftAlign>{formatDate(row.start)}</StyledTableCellLeftAlign>
                    <StyledTableCell>{row.hours}</StyledTableCell>
                    {!sm && (
                      <StyledTableCell>
                        <ReusableButton
                          aria-label='View request'
                          size='small'
                          style={{ width: '4rem' }}
                          href={`/requests/view/${row.id}`}
                        >
                          View
                        </ReusableButton>
                      </StyledTableCell>
                    )}
                  </TableRow>
                ) : null
              )
            ) : (
              <TableRow>
                <StyledTableCell colSpan={4}>
                  No history of {capitalizeRequestType(type)} events scheduled.
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TabTable;
