import { Typography } from '@mui/material';
import { FC } from 'react';
import ReusableButton from '../buttons/ReusableButton';
import BaseModal from './BaseModal';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  onReturnBack: () => void;
}

// reducing overall size of delete modal since base modal was created
const DeleteModalComponent: FC<ModalProps> = ({ open, onClose, onReturnBack }) => {
  const content = (
    <Typography sx={{ textAlign: 'left', fontSize: '1.15rem', paddingTop: '0.5rem' }}>
      Are you sure you want to delete this request?
    </Typography>
  );

  const actions = (
    <>
      <ReusableButton aria-label='Yes' onClick={onClose}>
        Yes
      </ReusableButton>
      <ReusableButton
        aria-label='Return to request'
        onClick={onReturnBack}
        style={{ marginLeft: 0 }}
      >
        Hop Back
      </ReusableButton>
    </>
  );

  return <BaseModal open={open} content={content} actions={actions} />;
};

export default DeleteModalComponent;
