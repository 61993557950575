import { Alert, Box, ClickAwayListener, Table, useMediaQuery } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled, { useTheme } from 'styled-components';
import { useQuery } from '@apollo/client';
import { GET_ALL_PENDING_DATE_EVENTS } from '../../../graphql/dateEvents/queries/getAllPendingDateEvents';
import ReusableButton from '../../../components/buttons/ReusableButton';
import formatDate from '../../../utils/FormatDate';
import { capitalizeRequestType } from '../../../utils/capitalizeRequestType';
import { RequestStatus } from '../../../enums/RequestStatus';
import Header from '../../../components/text/Header';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from '../../../providers/CurrentUserContextProvider';
import { useEffect } from 'react';
import Spinner from '../../../components/Spinner';
import { useDeleteRequest } from '../../../providers/DeleteRequestContextProvider';

interface User {
  username: string;
}

interface EventType {
  code: string;
}

export interface Event {
  id: number;
  user: User;
  eventType: EventType;
  start: string;
  hours: number;
  status?: RequestStatus;
}

interface StyledTableHeaderProps extends TableCellProps {
  textAlign?: 'left' | 'center' | 'right'; // Add other textAlign values if needed
}

export const StyledTableHeader = styled(TableCell)<StyledTableHeaderProps>(
  ({ theme, textAlign = 'left' }) => ({
    padding: theme.spacing(0.75),
    fontWeight: 700,
    lineHeight: '1.5rem',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1rem',
    textAlign,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0.1),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0.75),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1),
    },
  })
);

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0.5),
  fontSize: '1rem',
  lineHeight: '1.5rem',
  textAlign: 'left',
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(0.1),
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0.75),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(1),
  },
}));

const PendingEvents = () => {
  const { data: dateEvent, loading } = useQuery(GET_ALL_PENDING_DATE_EVENTS);
  const theme = useTheme();
  const { caxyLightGray } = theme.colors;
  const sm = useMediaQuery(theme.breakpoints.down('400'));
  const navigate = useNavigate();
  const { currentUserData } = useCurrentUser();
  const {
    isDeleted,
    setIsDeleted,
    userDeleteMessage,
    setUserDeleteMessage,
    requestedUserEmail,
    setRequestedUserEmail,
    yourDeleteMessage,
    setYourDeleteMessage,
  } = useDeleteRequest();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isDeleted) {
      timer = setTimeout(() => {
        setIsDeleted(false);
        setUserDeleteMessage('');
        setYourDeleteMessage('');
        setRequestedUserEmail('');
      }, 7000);
    }
    return () => clearTimeout(timer);
  }, [isDeleted, setIsDeleted, setUserDeleteMessage, setYourDeleteMessage, setRequestedUserEmail]);

  if (loading) return <Spinner />;

  const isCurrentUserTheOneWhoRequestedTheDateEvent =
    currentUserData?.userByEmail.email === requestedUserEmail;

  return (
    <>
      {isDeleted && (
        <ClickAwayListener
          onClickAway={() => {
            setIsDeleted(false);
            setUserDeleteMessage('');
            setYourDeleteMessage('');
            setRequestedUserEmail('');
          }}
        >
          <Alert
            sx={{ justifyContent: 'center', width: '17.5rem', margin: '0 auto', marginTop: '1rem' }}
            variant='filled'
            severity='error'
          >
            {isCurrentUserTheOneWhoRequestedTheDateEvent ? yourDeleteMessage : userDeleteMessage}
          </Alert>
        </ClickAwayListener>
      )}
      <Box
        sx={{
          width: dateEvent?.dateEvents.length ? { md: '40rem' } : '19.6rem',
          display: 'flex',
          flexDirection: 'column',
          marginX: '1rem',
          boxShadow: dateEvent?.dateEvents.length ? '1rem 1rem 2rem #00000033' : '',
          marginTop: '2rem',
          marginBottom: '2rem',
          borderRadius: '0.3rem',
          paddingTop: '1rem',
          paddingX: '1rem',
          backgroundColor: dateEvent?.dateEvents.length ? 'white' : caxyLightGray,
        }}
      >
        {dateEvent?.dateEvents.length ? (
          <>
            <Box sx={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>
              <Header
                sx={{
                  marginTop: 0,
                  marginBottom: '1.5rem',
                  marginLeft: '0.5rem',
                  borderBottom: '2px solid white',
                }}
              >
                Pending Events
              </Header>
            </Box>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableHeader
                      sx={{
                        fontWeight: 'bold',
                        width: '8rem',
                        position: 'sticky',
                        top: 0,
                        background: 'white',
                        zIndex: 1,
                        borderTop: '2px solid white',
                      }}
                    >
                      Owner
                    </StyledTableHeader>
                    <StyledTableHeader
                      sx={{
                        fontWeight: 'bold',
                        width: '5rem',
                        position: 'sticky',
                        top: 0,
                        background: 'white',
                        zIndex: 1,
                        borderTop: '2px solid white',
                      }}
                    >
                      Type
                    </StyledTableHeader>
                    <StyledTableHeader
                      sx={{
                        fontWeight: 'bold',
                        width: '10rem',
                        position: 'sticky',
                        top: 0,
                        background: 'white',
                        zIndex: 1,
                        borderTop: '2px solid white',
                      }}
                    >
                      Date
                    </StyledTableHeader>
                    <StyledTableHeader
                      sx={{
                        fontWeight: 'bold',
                        width: '4rem',
                        position: 'sticky',
                        top: 0,
                        background: 'white',
                        zIndex: 1,
                        borderTop: '2px solid white',
                      }}
                    >
                      Hours
                    </StyledTableHeader>
                    {!sm && (
                      <StyledTableHeader
                        sx={{
                          fontWeight: 'bold',
                          width: '5rem',
                          position: 'sticky',
                          top: 0,
                          background: 'white',
                          zIndex: 1,
                          borderTop: '2px solid white',
                        }}
                      >
                        Action
                      </StyledTableHeader>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dateEvent?.dateEvents.map((event: Event, index: number) => (
                    <TableRow
                      key={`${event.start}-${index}`}
                      onClick={() => sm && navigate(`/requests/view/${event.id}`)}
                    >
                      <StyledTableCell
                        sx={{
                          maxWidth: '8rem',
                          overflowX: 'scroll',
                          '&::-webkit-scrollbar': {
                            display: 'none',
                          },
                          scrollbarWidth: 'none',
                        }}
                      >
                        {event?.user.username}
                      </StyledTableCell>
                      <StyledTableCell>
                        {capitalizeRequestType(event?.eventType.code)}
                      </StyledTableCell>

                      <StyledTableCell>{formatDate(event.start)}</StyledTableCell>
                      <StyledTableCell>{event.hours}</StyledTableCell>
                      {!sm && (
                        <StyledTableCell>
                          <ReusableButton
                            size='small'
                            style={{ width: '5rem' }}
                            href={`/requests/view/${event.id}`}
                          >
                            View
                          </ReusableButton>
                        </StyledTableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Box sx={{ width: '18.5rem', marginTop: '-2rem' }}>
            <span style={{ textAlign: 'left' }}>There are no pending events.</span>
          </Box>
        )}
      </Box>
    </>
  );
};

export default PendingEvents;
