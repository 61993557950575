import { DateTime } from 'luxon';
import { useCurrentUser } from '../../providers/CurrentUserContextProvider';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { GET_PTO_DATA } from '../../graphql/users/queries/getPTOHoursRemaining';

export const PTOHour = styled(Typography)(() => ({
  fontSize: '1rem',
  width: '100%',
  marginTop: '1rem',
  textAlign: 'left',
}));

interface Props {
  variant?: 'compact';
}

const TimeUntilResets = ({ variant }: Props) => {
  const { currentUserData } = useCurrentUser();
  const user = currentUserData?.userByEmail;
  const today = DateTime.now();
  const startDate = user?.startDate ? DateTime.fromISO(user.startDate.toString()) : today;

  const { data: ptoHoursAvailable } = useQuery(GET_PTO_DATA, {
    variables: {
      id: user?.id,
    },
  });

  const anniversaryThisYear = DateTime.fromObject({
    month: startDate.month,
    day: startDate.day,
    year: today.year, // Use current year for comparison
  });

  // If the anniversary has already passed this year, calculate for next year's anniversary
  const nextAnniversary =
    anniversaryThisYear < today ? anniversaryThisYear.plus({ years: 1 }) : anniversaryThisYear;
  const daysUntilAnniversary = Math.ceil(nextAnniversary.diff(today, 'days').days);

  const firstOfJanuaryNextYear = DateTime.fromObject({
    year: today.year + 1,
    month: 1,
    day: 1,
  }); // Create a DateTime object for January 1st of the next year

  const daysUntilEndOfYear = Math.floor(firstOfJanuaryNextYear.diff(today, 'days').days);
  //if the unpaidHours field is null or undefined, return 0. else use the value
  const unpaidHours = user?.unpaidHours == null ? 0 : user.unpaidHours;

  if (variant === 'compact') {
    return (
      <Box>
        {daysUntilAnniversary !== 0 && user && (
          <>
            {ptoHoursAvailable && (
              <PTOHour>
                You have <strong>{ptoHoursAvailable.ptoData.totalRemainingHours}</strong> hours of
                PTO available*. Your PTO resets in <strong>{daysUntilAnniversary}</strong>{' '}
                {daysUntilAnniversary === 1 ? 'day' : 'days'}.
              </PTOHour>
            )}
            <PTOHour>
              You have <strong>{user.wfhDays}</strong> WFH days and <strong>{user.flexDays}</strong>{' '}
              Flex days available. These benefits reset in <strong>{daysUntilEndOfYear}</strong>{' '}
              {daysUntilEndOfYear === 1 ? 'day' : 'days'}.
            </PTOHour>
            {unpaidHours > 0 && (
              <PTOHour>
                <strong>{user.unpaidHours}</strong> unpaid hours.
              </PTOHour>
            )}
          </>
        )}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: '24.5rem',
        '@media (min-width: 20rem)': {
          width: '16rem',
        },
        '@media (min-width: 25rem)': {
          width: '21rem',
        },
        '@media (min-width: 28.125rem)': {
          width: '24.5rem',
        },
        margin: '0 auto',
        marginTop: 0,
      }}
    >
      {daysUntilAnniversary !== 0 ? (
        <PTOHour style={{ marginTop: '0.5rem' }}>
          You have <strong>{daysUntilAnniversary}</strong> more{' '}
          {daysUntilAnniversary === 1 ? 'day' : 'days'} to use your PTO before it resets on{' '}
          {nextAnniversary.toFormat('MMM d, yyyy')}.
        </PTOHour>
      ) : (
        ''
      )}

      <PTOHour>
        You have <strong>{daysUntilEndOfYear}</strong> {daysUntilEndOfYear === 1 ? 'day' : 'days'}{' '}
        until WFH and Flex benefits reset on {firstOfJanuaryNextYear.toFormat('MMM d, yyyy')}.
      </PTOHour>
      {unpaidHours > 0 && (
        <PTOHour>
          You have taken <strong>{user?.unpaidHours}</strong> unpaid hours.
        </PTOHour>
      )}
    </Box>
  );
};

export default TimeUntilResets;
