import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useState } from 'react';
import styled from 'styled-components';
import { getDaysUntilAnniversary } from '../../utils/getDaysUntilAnniversary';

interface TableData {
  username: string;
  email: string;
  startDate: Date;
  ptoHours: number;
  id: number;
  ptoHoursAvailable: number;
}

interface UserTableProps {
  data: TableData[];
  type: string;
}

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0.5),
  textAlign: 'left',
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(0.1),
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0.75),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(1),
  },
  fontSize: '1rem',
}));

const UserTable: React.FC<UserTableProps> = ({ data = [], type }) => {
  // State to track the current sort direction, defaulting to 'asc' (ascending)
  const [sortDirection, setSortDirection] = useState('asc');

  // Function to handle sorting when the PTO Ratio column header is clicked
  const handleSort = () => {
    // Toggle sort direction between 'asc' (ascending) and 'desc' (descending)
    setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
  };

  // Function to sort the data based on PTO Ratio and current sort direction
  const sortData = (data: TableData[]) => {
    // Create a copy of the data array to avoid mutating the original array
    const sortedData = [...data].sort((a, b) => {
      // Calculate the PTO Ratio for each entry
      const aRatio = a.ptoHours / getDaysUntilAnniversary(a.startDate).daysUntilAnniversary;
      const bRatio = b.ptoHours / getDaysUntilAnniversary(b.startDate).daysUntilAnniversary;

      // Sort based on the current sort direction
      if (sortDirection === 'asc') {
        return aRatio - bRatio;
      } else {
        return bRatio - aRatio;
      }
    });
    return sortedData;
  };

  // Get the sorted data based on the current sort direction
  const sortedData = sortData(data);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        paddingTop: '1rem',
        width: '90%',
      }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: 'bold', width: '8rem' }}>User</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 'bold', width: '10rem' }}>
                Hours Remaining
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 'bold', width: '11rem' }}>
                Days Until Anniversary
              </StyledTableCell>
              <StyledTableCell
                sx={{
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  width: '7rem',
                }}
                onClick={handleSort}
              >
                PTO Usage Ratio
                {/* Display the sort direction indicator based on the current sort direction */}
                {sortDirection === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((user, index) => (
              <TableRow key={index}>
                <StyledTableCell
                  sx={{
                    maxWidth: '4rem',
                    overflowX: 'scroll',
                    '&::-webkit-scrollbar': {
                      display: 'none',
                    },
                    scrollbarWidth: 'none',
                  }}
                >
                  {user.username}
                </StyledTableCell>
                <StyledTableCell>{user.ptoHoursAvailable}</StyledTableCell>
                <StyledTableCell>
                  {getDaysUntilAnniversary(user.startDate).daysUntilAnniversary}
                </StyledTableCell>
                <StyledTableCell style={{ cursor: 'pointer' }}>
                  {(
                    user.ptoHours / getDaysUntilAnniversary(user.startDate).daysUntilAnniversary
                  ).toFixed(2)}{' '}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {data.length === 0 && (
        <Typography sx={{ marginTop: '1rem' }}>
          No {type.toLowerCase()} spend users to show.
        </Typography>
      )}
    </Box>
  );
};

export default UserTable;
