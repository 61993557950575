import { Box, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import { GET_ALL_USERS } from '../../graphql/users/queries/getAllUsers';
import { Link } from 'react-router-dom';
import { sortData, SortType } from '../../utils/sortData';
import { Role } from '../../enums/role';
import formatDate from '../../utils/FormatDate';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Spinner from '../../components/Spinner';
import { StyledTableCell, StyledTableHeader } from '../User/Events/PendingEvents';

export interface GetAllUsers {
  id: number;
  username: string;
  email: string;
  role: Role;
  startDate: string;
  ptoRate: number;
  flexDays: number;
  wfhDays: number;
  emailSubscriptions: string;
  ptoHours: number;
  displayName: string;
  auth0Id: string | null;
  ptoHoursUsed: number;
  unpaidHours: number;
  exceptionDays: number;
  percentRemaining: number;
}

export interface AdminPageColumnData {
  id: number;
  user: string;
  ptoHoursRemaining: number;
  flexDaysRemaining: number;
  wfhDaysRemaining: number;
  exceptionTimeRemaining: number;
  hireDate: string;
  daysUntilAnniversary: number;
}

interface ColumnData {
  dataKey: keyof AdminPageColumnData;
  label: string;
  numeric?: boolean;
}

const columns: ColumnData[] = [
  {
    label: 'User',
    dataKey: 'user',
  },
  {
    label: 'PTO Hours Remaining',
    dataKey: 'ptoHoursRemaining',
    numeric: true,
  },
  {
    label: 'Flex Days Remaining',
    dataKey: 'flexDaysRemaining',
    numeric: true,
  },
  {
    label: 'WFH Days Remaining',
    dataKey: 'wfhDaysRemaining',
    numeric: true,
  },
  {
    label: 'Exception Time Remaining',
    dataKey: 'exceptionTimeRemaining',
    numeric: true,
  },
  {
    label: 'Days Until Anniversary',
    dataKey: 'daysUntilAnniversary',
  },
  {
    label: 'Hire Date',
    dataKey: 'hireDate',
  },
];

export const columnDataKeys = columns.map((column) => column.dataKey);

const createData = (
  id: number,
  user: string,
  ptoHoursRemaining: number,
  flexDaysRemaining: number,
  wfhDaysRemaining: number,
  exceptionTimeRemaining: number,
  hireDate: string,
  daysUntilAnniversary: number
): AdminPageColumnData => {
  return {
    id,
    user,
    ptoHoursRemaining,
    flexDaysRemaining,
    wfhDaysRemaining,
    exceptionTimeRemaining,
    hireDate,
    daysUntilAnniversary,
  };
};

const AdminUserPage = () => {
  const [rows, setRows] = useState<AdminPageColumnData[]>([]);
  const [showSortingArrow, setShowSortingArrow] = useState<boolean>(true);
  const { data, loading, error } = useQuery(GET_ALL_USERS);
  const [sortDirection, setSortDirection] = useState<SortType>('desc');
  const [selectedSortIndex, setSelectedSortIndex] = useState<number>(-1);

  useEffect(() => {
    if (data) {
      const updatedRows = data.users?.map((data: GetAllUsers) => {
        const givenDate = DateTime.fromISO(data.startDate);
        const today = DateTime.now().startOf('day');

        let nextAnniversary = givenDate.set({ year: today.year });
        if (nextAnniversary < today) {
          nextAnniversary = nextAnniversary.plus({ years: 1 });
        }

        const daysUntilAnniversary = Math.floor(nextAnniversary.diff(today, 'days').days);

        return createData(
          data.id,
          data.username,
          data.ptoHours,
          data.flexDays,
          data.wfhDays,
          data.exceptionDays,
          data.startDate,
          daysUntilAnniversary
        );
      });
      setRows(updatedRows);
    }
  }, [data]);

  if (loading) return <Spinner />;
  if (error) return <p>Error: {error.message}</p>;

  const handleDataSort = (column: ColumnData, index: number) => {
    const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(newSortDirection);
    setSelectedSortIndex(index);

    if (rows) {
      const sortedRows = sortData(rows, column.dataKey, newSortDirection);
      setRows(sortedRows);
    }
  };

  const displaySortIcon = (index: number) => {
    const iconStyles = {
      position: 'absolute',
      bottom: '-0.2rem',
      left: '.75rem',
      transform: 'translateX(-50%)',
      fontSize: { xs: '1rem', md: '1.2rem' },
    };

    if (showSortingArrow && selectedSortIndex === index) {
      return sortDirection === 'desc' ? (
        <KeyboardArrowDownIcon sx={iconStyles} />
      ) : (
        <KeyboardArrowUpIcon sx={iconStyles} />
      );
    }
  };

  const tableHeader = () => {
    return (
      <TableRow>
        {columns.map((column, index) => (
          <StyledTableHeader
            key={column.dataKey}
            variant='head'
            align='left'
            onMouseEnter={() => {
              setSelectedSortIndex(index);
              setShowSortingArrow(true);
            }}
            onMouseLeave={() => setShowSortingArrow(false)}
            onClick={() => handleDataSort(column, index)}
            sx={{
              position: 'sticky',
              padding: '1rem 0.5rem',
              cursor: 'pointer',
              backgroundColor: 'white',
              width: 'calc(100% / 6)',
            }}
          >
            {column.label}
            {displaySortIcon(index)}
          </StyledTableHeader>
        ))}
      </TableRow>
    );
  };

  return (
    <Box
      sx={{
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        margin: '2rem',
        marginBottom: '2rem',
        boxShadow: '1rem 1rem 2rem #00000033',
        borderRadius: '0.3rem',
        paddingX: '1rem',
        '@media (min-width: 20rem)': {
          width: '17.7rem',
        },
        '@media (min-width: 25rem)': {
          width: '23rem',
        },
        '@media (min-width: 33.125rem)': {
          width: '28.5rem',
        },
        '@media (min-width: 37.5rem)': {
          width: '35rem',
        },
        '@media (min-width: 48.438rem)': {
          width: '43rem',
        },
        '@media (min-width: 56.25rem)': {
          width: '50rem',
        },
        '@media (min-width: 62.5rem)': {
          width: '59rem',
        },
      }}
    >
      <TableContainer
        sx={{
          overflow: 'auto',
          maxHeight: '100%',
        }}
      >
        <Table stickyHeader>
          <TableHead>{tableHeader()}</TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <TableRow key={index}>
                <StyledTableCell
                  sx={{
                    maxWidth: '7rem',
                    overflowX: 'scroll',
                    '&::-webkit-scrollbar': {
                      display: 'none',
                    },
                    scrollbarWidth: 'none',
                  }}
                >
                  <Link
                    to={`/users/${row.id}/view`}
                    style={{ textDecoration: 'none', color: '#43b458', fontWeight: 'bold' }}
                  >
                    {row.user}
                  </Link>
                </StyledTableCell>
                <StyledTableCell>{row.ptoHoursRemaining}</StyledTableCell>
                <StyledTableCell>{row.flexDaysRemaining}</StyledTableCell>
                <StyledTableCell>{row.wfhDaysRemaining}</StyledTableCell>
                <StyledTableCell>{row.exceptionTimeRemaining}</StyledTableCell>
                <StyledTableCell>{row.daysUntilAnniversary}</StyledTableCell>
                <StyledTableCell sx={{ width: '6.8rem', whiteSpace: 'nowrap' }}>
                  {formatDate(row.hireDate)}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AdminUserPage;
