import { Box, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import { GET_ALL_USERS } from '../../graphql/users/queries/getAllUsers';
import { Link } from 'react-router-dom';
import { sortData, SortType } from '../../utils/sortData';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Spinner from '../../components/Spinner';
import { GetAllUsers } from '../Admin/AdminUserPage';
import { getDaysUntilAnniversary } from '../../utils/getDaysUntilAnniversary';
import { formatNumberWithDecimal } from '../../helpers/formattingAccruedBalance';
import { StyledTableCell, StyledTableHeader } from '../User/Events/PendingEvents';

export interface TotalBenefitsColumnData {
  id: number;
  user: string;
  ptoHoursUsed: number;
  ptoPercentRemaining: string;
  daysUntilReset: number;
  flexDaysUsed: number;
  wfhDaysUsed: number;
  exceptionsUsed: number;
  unpaidTimeUsed: number;
}

interface ColumnData {
  dataKey: keyof TotalBenefitsColumnData;
  label: string;
  numeric?: boolean;
}

const columns: ColumnData[] = [
  {
    label: 'User',
    dataKey: 'user',
  },
  {
    label: 'PTO Hours Used',
    dataKey: 'ptoHoursUsed',
    numeric: true,
  },
  {
    label: 'PTO % Remaining',
    dataKey: 'ptoPercentRemaining',
  },
  {
    label: 'Days Until Reset',
    dataKey: 'daysUntilReset',
    numeric: true,
  },
  {
    label: 'Flex Days Used',
    dataKey: 'flexDaysUsed',
    numeric: true,
  },
  {
    label: 'WFH Days Used',
    dataKey: 'wfhDaysUsed',
    numeric: true,
  },
  {
    label: 'Exceptions Used',
    dataKey: 'exceptionsUsed',
    numeric: true,
  },
  {
    label: 'Unpaid Time Used',
    dataKey: 'unpaidTimeUsed',
    numeric: true,
  },
];

export const columnDataKeys = columns.map((column) => column.dataKey);

const createData = (
  id: number,
  user: string,
  ptoHoursUsed: number,
  ptoPercentRemaining: string,
  daysUntilReset: number,
  flexDaysUsed: number,
  wfhDaysUsed: number,
  exceptionsUsed: number,
  unpaidTimeUsed: number
): TotalBenefitsColumnData => {
  return {
    id,
    user,
    ptoHoursUsed,
    ptoPercentRemaining,
    flexDaysUsed,
    daysUntilReset,
    wfhDaysUsed,
    exceptionsUsed,
    unpaidTimeUsed,
  };
};

const TotalBenefitsPage = () => {
  const [rows, setRows] = useState<TotalBenefitsColumnData[] | null>(null);
  const [showSortingArrow, setShowSortingArrow] = useState<boolean>(true);

  const { data, loading, error } = useQuery(GET_ALL_USERS, {
    variables: {
      isPtoHoursUsed: true,
    },
  });
  const [sortDirection, setSortDirection] = useState<SortType>('desc');
  const [selectedSortIndex, setSelectedSortIndex] = useState<number>(0);

  useEffect(() => {
    if (data) {
      const updatedRows = data.users?.map((data: GetAllUsers) => {
        const givenDate = DateTime.fromISO(data.startDate);
        const today = DateTime.now().startOf('day');

        let nextAnniversary = givenDate.set({ year: today.year });
        if (nextAnniversary < today) {
          nextAnniversary = nextAnniversary.plus({ years: 1 });
        }

        const percentRemaining = `${formatNumberWithDecimal(data.percentRemaining)}%`;

        return createData(
          data.id,
          data.username,
          data.ptoHoursUsed,
          percentRemaining,
          getDaysUntilAnniversary(data.startDate).daysUntilAnniversary,
          24 - data.flexDays,
          24 - data.wfhDays,
          24 - data.exceptionDays,
          data.unpaidHours
        );
      });
      setRows(updatedRows);
    }
  }, [data]);

  if (loading) return <Spinner />;
  if (error) return <p>Error: {error.message}</p>;

  const handleDataSort = (column: ColumnData, index: number) => {
    const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(newSortDirection);
    setSelectedSortIndex(index);

    if (rows) {
      const sortedRows = sortData(rows, column.dataKey, newSortDirection);
      setRows(sortedRows);
    }
  };

  const displaySortIcon = (index: number) => {
    const iconStyles = {
      position: 'absolute',
      bottom: '-0.2rem',
      left: '.75rem',
      transform: 'translateX(-50%)',
      fontSize: { xs: '1rem', md: '1.2rem' },
    };

    if (showSortingArrow && selectedSortIndex === index) {
      return sortDirection === 'desc' ? (
        <KeyboardArrowDownIcon sx={iconStyles} />
      ) : (
        <KeyboardArrowUpIcon sx={iconStyles} />
      );
    }
  };

  const tableHeader = () => {
    return (
      <TableRow>
        {columns.map((column, index) => (
          <StyledTableHeader
            key={column.dataKey}
            variant='head'
            align='left'
            onMouseEnter={() => {
              setSelectedSortIndex(index);
              setShowSortingArrow(true);
            }}
            onMouseLeave={() => setShowSortingArrow(false)}
            onClick={() => handleDataSort(column, index)}
            sx={{
              position: 'sticky',
              padding: '1rem 0.5rem',
              cursor: 'pointer',
              backgroundColor: 'white',
              width: 'calc(100% / 8)',
            }}
          >
            {column.label}
            {displaySortIcon(index)}
          </StyledTableHeader>
        ))}
      </TableRow>
    );
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          marginX: '2rem',
          marginTop: '2rem',
          marginBottom: '2rem',
          boxShadow: '1rem 1rem 2rem #00000033',
          borderRadius: '0.3rem',
          paddingX: '1rem',
          overflow: 'scroll',
          '@media (min-width: 20rem)': {
            width: '16.5rem',
          },
          '@media (min-width: 25rem)': {
            width: '22.5rem',
          },
          '@media (min-width: 34.375rem)': {
            width: '26rem',
          },
          '@media (min-width: 37.5rem)': {
            width: '32rem',
          },
          '@media (min-width: 48.438rem)': {
            width: '40rem',
          },
          '@media (min-width: 56.25rem)': {
            width: '50rem',
          },
          '@media (min-width: 68.75rem)': {
            width: '55rem',
          },
        }}
      >
        <TableContainer
          sx={{
            overflowX: 'auto',
            overflowY: 'auto',
            maxHeight: '100%',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            scrollbarWidth: 'none',
          }}
        >
          <Table stickyHeader>
            <TableHead>{tableHeader()}</TableHead>
            <TableBody>
              {rows?.map((row, index) => (
                <TableRow key={index}>
                  <StyledTableCell
                    sx={{
                      maxWidth: '7rem',
                      overflowX: 'scroll',
                      '&::-webkit-scrollbar': {
                        display: 'none',
                      },
                      scrollbarWidth: 'none',
                    }}
                  >
                    <Link
                      to={`/users/${row.id}/view`}
                      style={{ textDecoration: 'none', color: '#43b458', fontWeight: 'bold' }}
                    >
                      {row.user}
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell>{row.ptoHoursUsed}</StyledTableCell>
                  <StyledTableCell>{row.ptoPercentRemaining}</StyledTableCell>
                  <StyledTableCell>{row.daysUntilReset}</StyledTableCell>
                  <StyledTableCell>{row.flexDaysUsed}</StyledTableCell>
                  <StyledTableCell>{row.wfhDaysUsed}</StyledTableCell>
                  <StyledTableCell>{row.exceptionsUsed}</StyledTableCell>
                  <StyledTableCell>{row.unpaidTimeUsed}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default TotalBenefitsPage;
